import styled from 'styled-components';

export const Container = styled.section`
  background: #ffffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  margin-bottom: 130px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
`;

export const Logo = styled.div`
  width: 100px;
  height: 100px;

  .Icons {
    width: 100px;
    height: 100px;
  }
`;
