import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal, Button, Icons, styleHelpers, LoadingDots } from '@pik/pik-ui';

import { sendEmail, hidePopup } from '../../../services/errorPopup/actions';

import S from './styles';

const SUCCESS_SENT_TITLE = 'Письмо отправлено';
const ERROR_SENT_TITLE = 'Ошибка. Письмо не отправлено';

const SUCCESS_SENT_MESSAGE = 'Чуточку терпения, скоро мы всё починим.';
const ERROR_SENT_MESSAGE = 'Не удалось отправить письмо с жалобой';

const SearchPopup = (props) => {
  const {
    errorMessage,
    errorInfo,
    userEmail,

    isShow,
    hidePopupAction,

    sendEmailAction,

    sendEmailRequest,
    sendEmailSuccess,
    sendEmailError,
  } = props;

  let title = 'Хм, что-то не так…';

  if (sendEmailSuccess) {
    title = SUCCESS_SENT_TITLE;
  } else if (sendEmailError) {
    title = ERROR_SENT_TITLE;
  }

  let message = 'Пожалуйтесь на эту ошибку, чтобы PIK.Team работал лучше,  и попробуйте ещё раз позже.';

  if (sendEmailSuccess) {
    message = SUCCESS_SENT_MESSAGE;
  } else if (sendEmailError) {
    message = ERROR_SENT_MESSAGE;
  }

  return (
    <Modal
      {...props}
      active={isShow}
      onClose={() => {
        hidePopupAction();
      }}
      fullScreen={false}
      width="l"
      background="transparent"
      header={<S.Header>
        <S.Title>{title}</S.Title>

        <S.CloseBtn
          onClick={() => {
            hidePopupAction();
          }}
        ><Icons type="cross" /></S.CloseBtn>
      </S.Header>}
      headerProps={{
        showIcon: false,
      }}
    >
      <S.Container>
        <S.Content>
          <S.Text>{message}</S.Text>

          <S.Button>
            <Button
              disabled={sendEmailSuccess}
              loading={sendEmailRequest}
              onClick={() => {
                let text = 'Юзер:'  + '\n' + userEmail + '\n\n' + 'Ошибка:' + '\n'  + errorMessage;

                if (errorInfo) {
                  text += '\n\n' + 'Доп. информация:' + '\n' + JSON.stringify(errorInfo);
                };

                sendEmailAction({
                  subject: 'Проблема с логином',
                  text: text,
                });
              }}
              type="secondary"
              fullWidth
              size="m"
              text="Нажаловаться!"
            />
          </S.Button>
        </S.Content>
      </S.Container>
    </Modal>
  );
};

const mapStateToProps = ({ errorPopupService }) => ({
  isShow: errorPopupService.isShow,
  sendEmailRequest: errorPopupService.sendEmailRequest,
  sendEmailSuccess: errorPopupService.sendEmailSuccess,
  sendEmailError: errorPopupService.sendEmailError,
});

const mapDispatchToProps = {
  hidePopupAction: hidePopup,
  sendEmailAction: sendEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPopup);
