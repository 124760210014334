import styled from 'styled-components';
import Link from 'next/link';

import { styleHelpers, media } from '@pik/pik-ui';

const S = {};

S.Container = styled.div`
  display: flex;
  justify-content: center;
  // min-height: 100%;
  // box-shadow: -5px 5px 6px rgba(164, 163, 163, 0.3);
`;

S.Header = styled.div`
  position: relative;
  margin: 0 auto;
  width: 670px;
  padding-top: 30px;
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 16px;
  line-height: 1.33;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(15px);
  // border-bottom: 1px solid ${styleHelpers.colors.grayExtraLight};

  @media ${media.mobile} {
    width: 100vw;
    padding: 20px;
    padding-right: 70px;
  }
`;

S.Title = styled.h2`
  font-size: 28px;
  font-weight: 600;

  @media ${media.mobile} {
    font-size: 16px;
  }
`;

S.CloseBtn = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover, &:active {
    cursor: pointer;

    svg {
      fill: ${styleHelpers.colors.primary};
    }
  }

  svg {
    fill: ${styleHelpers.colors.gray};
    transition: fill 0.2s ease;
  }
`;

S.Content = styled.div`
  width: 670px;
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(15px);

  @media ${media.mobile} {
    width: 100vw;
    padding: 20px;
  }
`;

S.Text = styled.p`
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 1.2;

  @media ${media.mobile} {
    font-size: 16px;
  }
`;

S.Button = styled.div`
  width: 194px;
  margin: 0 auto;
`;

export default S;
