import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icons } from '@pik/pik-ui';

import LoginForm from '../LoginForm';

import {
  Container,
  Content,
  LogoWrapper,
  Logo,
} from './styles';

class Login extends Component {
  static propTypes = {
  };

  static defaultProps = {
  };

  render() {
    return (
      <Container>
        <LogoWrapper>
          <Logo>
            <Icons type="logo" />
          </Logo>
        </LogoWrapper>
        <Content>
          <LoginForm />
        </Content>
      </Container>
    );
  }
}

export default Login;
