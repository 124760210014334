export default {
  peephole: (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4C1 4 2.99153 1 6.02119 1C9.05085 1 11 4 11 4"
        stroke="#A6A6A6"
        strokeLinecap="round"
      />
      <path
        d="M11 4C11 4 9.00847 7 5.97881 7C2.94915 7 1 4 1 4"
        stroke="#A6A6A6"
        strokeLinecap="round"
      />
      <circle cx="6" cy="4" r="1.5" stroke="#A6A6A6" />
    </svg>
  ),

  peepholeClosed: (
    <svg
      width="27"
      height="20"
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 7C26 7 21.0212 15 13.447 15C5.87288 15 0.999999 7 0.999999 7"
        stroke="#FC4C02"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M14 19L14 15"
        stroke="#FC4C02"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M25.3624 14.2278L23 11"
        stroke="#FC4C02"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1 14.2122L3.38364 11"
        stroke="#FC4C02"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  ),
};
